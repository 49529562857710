<template>
  <div>
    <component :is="userData === undefined ? 'div' : 'b-card'">

      <div v-if="!userData" class="text-center">
          <b-spinner label="Tunggu..."></b-spinner>
      </div>

      <div v-if="userData">
        <h4>
          <feather-icon
            icon="UserIcon"
            size="18"
          /> &nbsp;
          Akun
        </h4>
        <user-view-content
          :user-data="userData"
          class="mt-2 pt-75"
        />

          <b-row class="mt-2">
            <b-col>
              <div style="float: left">
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  @click="edit()"
                >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                /><span>Ubah</span>
                </b-button>
              </div>
              <div style="float: right">
                <b-button size="md" variant="outline-primary" @click="back()">
                  <feather-icon
                    icon="ArrowLeftIcon"
                    class="mr-50"
                  /><span>Kembali</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

      </div>

    </component>

    <b-card v-if="userData">
        <user-book :rows="userData.libraries" />
    </b-card>

    <b-card v-if="userData">
        <user-transaction :rows="userData.transactions" />
    </b-card>

    <b-card v-if="userData">
        <user-wishlist :rows="userData.wishlists" />
    </b-card>

  </div>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BSpinner, BButton, BCol, BRow
} from 'bootstrap-vue'
import router from '@/router'
import UserViewContent from './UserViewContent.vue'
import UserViewProfileContent from './UserViewProfileContent.vue'
import UserWishlist from './UserWishlist.vue'
import UserBook from './UserBook.vue'
import UserTransaction from './UserTransaction.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BSpinner,
    BButton,
    BCol,
    BRow,

    UserViewContent,
    UserViewProfileContent,
    UserWishlist,
    UserBook,
    UserTransaction,
  },
  data() {
    return {
      userData: null,
    }
  },
  methods: {
    edit() {
        this.$router.push('/users/' + this.userData.id + '/edit')
    },
    back() {
        this.$router.push('/users')
    }, 
    fetchUserData() {
      const id = router.currentRoute.params.id

      this.$http.get('/admin/v1/users/' + id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.userData = {
              id: currentData.id,
              name: currentData.name,
              email: currentData.email,
              phone: currentData.phone,
              address: currentData.address,
              church: currentData.church,
              church_name: currentData.church_name,
              church_jemaat_id: currentData.church_jemaat_id,
              status: (currentData.email_verified_at !== null),
              photo: currentData.photo,
              email_verified_at: currentData.email_verified_at,
              roles: currentData.roles,
              role: (currentData.roles !== null && currentData.roles.length > 0) ? currentData.roles[0].name : null,
              books: currentData.books,
              libraries: currentData.libraries,
              wishlists: currentData.wishlists,
              transactions: currentData.transactions,
            }
          }
      })
    }
  },
  created() {
    this.fetchUserData()
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>