<template>
  <div class="pb-2">
      <b-row>

        <!-- Field: No. Tel -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <h5>No. Tel</h5>
          <p>{{ (userData.profile) ? userData.profile.phone : '-' }}</p>
        </b-col>

        <!-- Field: Home Address -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <h5>Home Address</h5>
          <p>{{ (userData.profile) ? userData.profile.home_address : '-' }}</p>
        </b-col>

        <!-- Field: Personal Email -->
        <!-- <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <h5>Personal Email</h5>
          <p>{{ (userData.profile) ? userData.profile.personal_email : '-' }}</p>
        </b-col> -->

      </b-row>

      <b-row class="mt-2">

        <!-- Field: Company -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <h5>Company Name</h5>
          <p>{{ (userData.profile) ? userData.profile.company : '-' }}</p>
        </b-col>

        <!-- Field: Brand Name -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <h5>Brand Name</h5>
          <p>{{ (userData.profile) ? userData.profile.brand_name : '-' }}</p>
        </b-col>

        <!-- Field: Industry -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <h5>Company Industry</h5>
          <p>{{ (userData.profile) ? userData.profile.industry : '-' }}</p>
        </b-col>

        <!-- Field: Company Size -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <h5>Company Size</h5>
          <p>{{ (userData.profile) ? userData.profile.company_size : '-' }}</p>
        </b-col>

        <!-- Field: Company Address -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <h5>Company Address</h5>
          <p>{{ (userData.profile) ? userData.profile.company_address : '-' }}</p>
        </b-col>

        <!-- Field: Company Country -->
        <b-col
          cols="12"
          md="4"
        >
          <h5>Company Country</h5>
          <p>{{ (userData.profile && userData.profile.company_city.state.country) ? userData.profile.company_city.state.country.name : '-' }}</p>
        </b-col>

        <!-- Field: Company Province -->
        <b-col
          cols="12"
          md="4"
        >
          <h5>Company Province</h5>
          <p>{{ (userData.profile && userData.profile.company_city.state) ? userData.profile.company_city.state.name : '-' }}</p>
        </b-col>

        <!-- Field: Company City -->
        <b-col
          cols="12"
          md="4"
        >
          <h5>Company City</h5>
          <p>{{ (userData.profile && userData.profile.company_city) ? userData.profile.company_city.name : '-' }}</p>
        </b-col>

      </b-row>
      <b-row class="mt-2">

        <!-- Field: Office No. Tel -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <h5>Office No. Tel</h5>
          <p>{{ (userData.profile) ? userData.profile.company_phone : '-' }}</p>
        </b-col>

        <!-- Field: Office Email -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <h5>Office Email</h5>
          <p>{{ (userData.profile) ? userData.profile.company_email : '-' }}</p>
        </b-col>

        <!-- Field: Job Title -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <h5>Job Title</h5>
          <p>{{ (userData.profile) ? userData.profile.position : '-' }}</p>
        </b-col>

        <!-- Field: Job Level -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <h5>Job Level</h5>
          <p>{{ (userData.profile) ? userData.profile.job_level : '-' }}</p>
        </b-col>

      </b-row>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BBadge
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BBadge,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    statusVariant(emailVerifiedAt) {
      return emailVerifiedAt ? 'light-success' : 'light-danger'
    },
    roleVariant(roles) {
      if(roles === undefined || roles === null) {
        return 'light-info'
      }

      if(roles.length > 0) {
        if(roles[0].name == "Super Admin") {
          return 'light-primary'
        }
        else 
        if(roles[0].name == "Admin") {
          return 'light-primary'
        }
        else
        if(roles[0].name == "User") {
          return 'light-warning'
        }
        else {
          return 'light-info'
        }
      }
      else {
        return 'light-info'
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
